import { CONFIG_URL } from '../helpers/constants'

const api = {
   URI_ENDPOINT: CONFIG_URL.SERVICE_URL,
   auth: {
      refreshToken: '/auth/refresh-tokens',
      login: '/auth/login',
      register: '/auth/register',
      getUserInfo: '/auth/getUserInfo',
      updateInfo: '/auth/updateInfo',
      changePassword: '/auth/changePassword',
      resetPassword: '/auth/reset-password',
      forgotPassword: '/auth/forgot-password',
      logout: '/auth/logout',
      verifyEmail: '/auth/verify-email',
      sendEmail: '/auth/send-verification-email'
   },
   user: {
      getProfile: '/profiles',
      updateProfile: '/profiles',
      changePassword: '/profiles/change-password',
      transaction: '/profiles/transaction'
   },
   upload: {
      uploadFile: '/upload'
   },
   productType: {
      getList: '/product-type',
      edit: '/product-type',
      create: '/product-type',
      delete: '/product-type'
   },
   designType: {
      getList: '/design-type',
      edit: '/design-type',
      create: '/design-type',
      delete: '/design-type'
   },
   job: {
      getList: '/job',
      getDetail: '/job',
      lastJob: '/job/last-job',
      getFilterOption: '/listFilterOptions',
      receive: '/job/receive',
      reReceive: '/job/re-receive',
      review: '/job/review',
      designerUpdateUrl: '/job/designer-update',
      confirm: '/job/confirm',
      delete: '/job/delete',
      cancel: '/job/cancel',
      update: '/job/edit',
      count: '/job/count',
      rate: '/job/rate',
      isReceive: '/job/is-receive',
      total: '/job/total',
      sameNumber: '/job/sample-number',
      assignee: '/job/assignee',
      priority: '/job/priority',
      countCancel: 'job/count-cancel'
   },
   exchangeFee: {
      getList: '/exchange-fee',
      getFeeInfo: '/exchange-fee', //{{url}}/v1/exchange-fee?designTypeId=1&productTypeId=2
      edit: '/exchange-fee',
      create: '/exchange-fee',
      delete: '/exchange-fee'
   },
   activityHistory: {
      getList: '/history'
   },
   groupChat: {
      getList: '/chat',
      sendMessage: '/chat/message',
      getListMessage: '/chat/message'
   },
   managerUser: {
      getUser: '/users',
      create: '/users',
      update: '/users',
      delete: '/users',
      transaction: '/users/transaction'
   },
   config: {
      create: '/config',
      update: '/config',
      getList: '/config',
      getListSupport: '/config/get-link-support'
   },
   debt: {
      getList: '/debts',
      total: 'debts/total'
   },
   funds: {
      create: '/funds',
      update: '/funds',
      approve: '/funds/approve',
      getList: '/funds',
      total: '/funds/total'
   },
   notification: {
      get: '/notifications',
      mark_read: '/notifications/mark-read'
   },
   dashboard: {
      jobFinish: "dashboard/job-finish",
      revenue: "dashboard/revenue",
   }
}

export default api

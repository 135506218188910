import { observable, action } from "mobx";
import { getUniqueCodeBank, tabIDs } from "../helpers/constants";
import { Request2 } from "src/common/Request2";
import api from "src/common/api";
import { isEmpty } from "lodash";
import { customAlphabet } from "nanoid";
// import { WsCode } from "../helpers/Wscode";
const designerConfigDefault = {
  timeJob: "",
  amountHold: "",
  rangeDate: "",
  linkSupport: "",
  maxReceive: "",
  minWithdraw: '',
  minRecharge: '',
  rangeJobFinish: ''
}
export class CommonConfigStore {

  @observable listNumberItems: any = {
    results: [{ name: '' }, { name: '' }],
    page: 1,
    limit: 5,
    totalPages: 1,
    totalResults: 1,
  };

  @observable linkSupport: string = '';
  @observable activeKeyMain: string = tabIDs.designer;
  @observable productTypesList: any = []
  @observable designTypesList: any = []
  @observable exchangeFeeList: any = []
  @observable designerConfigList: any = designerConfigDefault
  @observable activeKeyPhiSan: string = this.designTypesList[0]?.id;
  @observable bankAccount = {
    BANK_ID: 'TCB',
    ACCOUNT_NO: '330030',
    AMOUNT: '',
    DESCRIPTION: getUniqueCodeBank(), // Tối đa 50 kí tự
    ACCOUNT_NAME: 'PHAM QUANG VINH'
  }
  @action
  resetExchangeFeeList() {
    this.exchangeFeeList = [];
  }
  @action
  setExchangeFeeList(value: any) {
    this.resetExchangeFeeList();
    this.exchangeFeeList = [...value];
  }
  @action
  setActiveKeyMain(value: string) {
    this.activeKeyMain = value;
  }
  @action
  setActiveKeyPhiSan(value: string) {
    this.activeKeyPhiSan = value;
  }
  @action
  resetBankAccount() {
    const nanoBank = customAlphabet('1234567890qwertyuioplkjhgfdsazxcvbnm', 7)
    this.bankAccount = {
      BANK_ID: 'TCB',
      ACCOUNT_NO: '330030',
      AMOUNT: '',
      DESCRIPTION: nanoBank().toUpperCase(), // Tối đa 50 kí tự
      ACCOUNT_NAME: 'PHAM QUANG VINH'
    }
  }

  @action
  async getListSupport() {
    const result = await Request2.get(api.config.getListSupport)
    // console.log('------getListSupport', JSON.stringify(result));
    if (!isEmpty(result)) {
      this.linkSupport = result?.linkSupport;
    }
  }

  @action
  async getProductTypeList(params: any = {}) {
    const result = await Request2.getWithToken(api.productType.getList, params)
    // console.log('------getProductTypeList', JSON.stringify(result));
    if (!isEmpty(result)) {
      this.productTypesList = result;
    }
  }

  @action
  async createProductType(name: string) {
    const body = { name };
    const DOMAIN = `${api.productType.create}`;
    // console.log(
    //   "\nDOMAIN; ",
    //   DOMAIN, 'body', body
    // );
    const result = await Request2.postWithToken(body, DOMAIN);
    // console.log(
    //   "result; actionCreateNcc",
    //   JSON.stringify(result),
    //   "\nDOMAIN; ",
    //   DOMAIN
    // );
    return result?.data;
  }

  @action
  async editProductType(id: string, name: string, index?: string) {
    let body: any = { name };
    if (index) {
      body.index = index
    }
    const DOMAIN = `${api.productType.edit}/` + id;
    // console.log(
    //   "\nDOMAIN; ",
    //   DOMAIN, 'body', body
    // );
    const result = await Request2.putWithToken(body, DOMAIN);
    // console.log(
    //   "result; editProductType",
    //   JSON.stringify(result),
    //   "\nDOMAIN; ",
    //   DOMAIN
    // );
    return result?.data;
  }

  @action
  async deleteProductType(id: string) {
    const body = {};
    const DOMAIN = `${api.productType.delete}/${id}`;
    const result = await Request2.deleteWithToken(body, DOMAIN);
    // console.log(
    //   "result; delete",
    //   JSON.stringify(result),
    //   "\nDOMAIN; ",
    //   DOMAIN
    // );
    return result;
  }


  @action
  async getDesignTypeList(params: any = {}) {
    const result = await Request2.getWithToken(api.designType.getList, params)
    // console.log('------getDesignTypeList', JSON.stringify(result));
    if (!isEmpty(result)) {
      this.designTypesList = result;
    }
  }

  @action
  async createDesignType(name: string) {
    const body = { name };
    const DOMAIN = `${api.designType.create}`;
    // console.log(
    //   "\nDOMAIN; ",
    //   DOMAIN, 'body', body
    // );
    const result = await Request2.postWithToken(body, DOMAIN);
    // console.log(
    //   "result; createDesignType",
    //   JSON.stringify(result),
    //   "\nDOMAIN; ",
    //   DOMAIN
    // );
    return result?.data;
  }


  @action
  async editDesignType(id: string, name: string, index?: string) {
    let body: any = { name };
    if (index) {
      body.index = index;
    }
    const DOMAIN = `${api.designType.create}/` + id;
    // console.log(
    //   "\nDOMAIN; ",
    //   DOMAIN, 'body', body
    // );
    const result = await Request2.putWithToken(body, DOMAIN);
    // console.log(
    //   "result; editDesignType",
    //   JSON.stringify(result),
    //   "\nDOMAIN; ",
    //   DOMAIN
    // );
    return result?.data;
  }

  @action
  async deleteDesignType(id: string) {
    const body = {};
    const DOMAIN = `${api.designType.delete}/${id}`;
    const result = await Request2.deleteWithToken(body, DOMAIN);
    // console.log(
    //   "result; delete",
    //   JSON.stringify(result),
    //   "\nDOMAIN; ",
    //   DOMAIN
    // );
    return result;
  }

  @action
  async getConfigDesigner(params: any = {}) {
    const result = await Request2.getWithToken(api.config.getList, params)
    // console.log('------getConfigDesigner', JSON.stringify(result));
    if (!isEmpty(result)) {
      this.designerConfigList = { ...result };
    }
  }

  @action
  async updateConfigDesigner(params: any = {}) {
    const result = await Request2.putWithToken(params, api.config.update)
    // console.log('------updateConfigDesigner', JSON.stringify(result));
    // if (!isEmpty(result)) {
    //   this.designerConfigList = { ...result };
    // }
    return result;
  }

  @action
  async getExchangeFeeList(designTypeId: string) {
    const result = await Request2.getWithToken(api.exchangeFee.getList + '/' + designTypeId, {})
    // console.log('------getExchangeFeeList', JSON.stringify(result));
    if (!isEmpty(result)) {
      this.exchangeFeeList = result;
    }
  }

  @action
  async updateExchangeFee(designTypeId: string, params: any = {}) {
    const result = await Request2.putWithToken(params, api.exchangeFee.edit + '/' + designTypeId)
    // console.log('------updateExchangeFee', JSON.stringify(result));
    // if (!isEmpty(result)) {
    //   this.designerConfigList = { ...result };
    // }
    return result;
  }

  @action
  async resetStore() {
    // this.listProductType = { ...resultInit };
  }
}


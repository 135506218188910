import { ROUTER_PATH } from 'src/screens/routes/metaData'
import { GLOBAL_CLIENT } from './GlobalClient'
// import { nanoid } from 'nanoid'
import { customAlphabet } from 'nanoid'
import moment from 'moment'
// const nanoid = customAlphabet('1234567890qwertyuioplkjhgfdsazxcvbnm', 7);
const nanoid = customAlphabet('0123456789', 7)
const nanoBank = customAlphabet('1234567890qwertyuioplkjhgfdsazxcvbnm', 7)
export const ROLE = {
   ADMIN: 'admin',
   CREATOR: 'creator',
   DESIGNER: 'designer',
   ACCOUNTANT: 'accountant',
   SUPPORTER: 'supporter'
}

export const SOCKET_EVENT = {
   NEW_JOB: 'newJob',
   CHANGE_STATUS: 'changeStatus',
   MESSAGE: 'message',
   NOTIFICATION: 'notification',
   REJECT_JOB: 'rejectJob',
   CONNECTED :'connected',
   RECEIVE_JOB:'receiveJob'
   
}
export const STATUS_ACTIVITY = {
   NEW: 0,
   DOING: 1,
   REVIEW: 2,
   REJECT: 3,
   FINISH: 4,
   EDIT: 5,
   UPDATE_URL: 6
}

export const getTitleFromStatus = (status: number) => {
   switch (status) {
      case STATUS_ACTIVITY.NEW:
         return 'danh sách công việc'
      case STATUS_ACTIVITY.DOING:
         return 'việc đang xử lí'
      case STATUS_ACTIVITY.REVIEW:
         return 'việc cần review'
      case STATUS_ACTIVITY.REJECT:
         return 'việc cần sửa'

      case STATUS_ACTIVITY.FINISH:
         return 'việc hoàn thành'
      default:
         break
   }
   return ''
}

export const STATUS_ACTIVITY_ITEM = {
   NEW: 0, //creator
   DOING: 1, // designer
   REVIEW: 2, // designer
   REJECT: 3, //creator
   FINISH: 4, //creator
   EDIT: 5, //creator
   UPDATE_URL: 6, // designer
   DELETE: 7, //creator
   CANCEL: 8
}

export const getStatusText = (status: number) => {
   switch (status) {
      case STATUS_ACTIVITY.NEW:
         return 'Mới'
      case STATUS_ACTIVITY.DOING:
         return 'Đang xử lí'
      case STATUS_ACTIVITY.REVIEW:
         return 'Review'
      case STATUS_ACTIVITY.REJECT:
         return 'Cần sửa lại'

      case STATUS_ACTIVITY.FINISH:
         return 'Hoàn thành'
      default:
         break
   }
}

export const STATUS_JOB = {
   NEW: 0,
   DOING: 1,
   REVIEW: 2,
   REJECT: 3,
   FINISH: 4
}

export const BUTTON_STATUS = [
   { label: 'Nhận việc', status: 1, type: 'primary', padding: '6px 8px' },
   { label: 'Đang làm', status: 2, type: 'primary' },
   { label: 'Review', status: 3, type: 'info' },
   { label: 'Sửa', status: 4, type: 'secondary' },
   { label: 'Hoàn thành', status: 5, type: 'success' }
]

export const CARD_BUTTON_ACTION = [
   { status: 1, path: ROUTER_PATH.JobReceive, isAction: true },
   { status: 2, path: '#b28900' },
   { status: 3, path: '#651fff' },
   { status: 4, path: '#607d8b' },
   { status: 5, path: '#4caf50' }
]
export const Number_impressions = 30
export const tabIDs = { designer: '1', phiSan: '2' }
export const tabIDsBusinessResult = { designer: '1', creator: '2' }
export const tabIDsCompletedQuantity = { designer: '1', creator: '2' }
export const tabIDsNumberEdit = { designer: '1', creator: '2' }
export const FILTER_TIME = [
   { type: 'lastWeek', title: '7 ngày gần đây', startTime: moment().subtract(7, 'd'), endTime: moment() },
   { type: 'lastMonth', title: '30 ngày gần đây', startTime: moment().subtract(30, 'd'), endTime: moment() },
   { type: 'secondMonth', title: '60 ngày gần đây', startTime: moment().subtract(60, 'd'), endTime: moment() },
   { type: 'thirdMonth', title: '90 ngày gần đây', startTime: moment().subtract(90, 'd'), endTime: moment() },
   { type: 'custom', title: 'Tuỳ chỉnh', startTime: moment().subtract(7, 'd'), endTime: moment() }
]

export const LOCAL_STORAGE = {
   DATA_AUTH: 'DATA_AUTH',
   FIRSTLAUNCH: 'FIRSTLAUNCH',
   REMEMBER_ME: 'REMEMBER_ME',
   TOKENS: 'TOKENS'
}

export const STATUS_LOGIN = {
   WAIT_LOGIN: 0,
   LOGIN_SUCCESS: 1,
   NOT_LOGIN: 2
}

export const CONFIG_URL = {
   SERVICE_URL: process.env.REACT_APP_API_ENDPOINT,
   IMAGE_URL: process.env.REACT_APP_IMAGE_ENDPOINT
}

export const ERROR_CODES = {
   SUCCESS: 200,
   REQUEST_FAILED: 400,
   UNAUTHORIZED: 401
}

export const TIME_OUT_API = 30000

export const TAB_SIZE = 992
export const PAGE_SIZE = 100
export const NOTI_SIZE = 12
export const PAGE_SIZE_CHAT = 200
export const PAGE_SIZE_FULL = 1000

export const DATE_TIME_FORMAT_BACKEND = 'HH:mm:ss YYYY-MM-DD'

export const DATE_TIME_FORMAT_CLIENT = 'HH:mm DD-MM-YYYY'
export const DATE_TIME_FORMAT_CLIENT_NEW = 'HH:mm - DD/MM/YYYY'
export const DATE_TIME_FORMAT_CLIENT_short = 'HH:mm DD-MM-YY'
export const DATE_TIME_FORMAT_CLIENT_short2 = 'HH:mm DD-MM'

export const DATE_FORMAT_CLIENT = 'DD-MM-YYYY'
export const DATE_FORMAT_CLIENT_PARAMS = 'DD/MM/YYYY'

export const DATE_FORMAT_BACKEND = 'YYYY-MM-DD'
export const DATE_FORMAT_ACTiVITY = 'HH:mm - DD/MM'

export const CREATE_SOURCE = 'crm_voucher'

export const COMMON_STATUS = {
   ALL: -1,
   ACTIVE: 1,
   INACTIVE: 0
}

export const GENDER = {
   MALE: 1,
   FEMALE: 0
}

export const ROLE_ID = {
   ADMIN: 1,
   PARTNER: 4
}

export const PHONE_PATTERN = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g

export const UPLOAD_FILE_TYPE = '.jpg,.jpeg,.png'

export const UPLOAD_FILE_EXCEL_TYPE = '.xls,.xlsx,.xlsm'

export const TYPE_DATE_PICKER = {
   RANGE: 'RANGE'
}

export const STATUS_KIT_AND_ISDN = {
   ALL: -1,
   IN_STOCK: 0, // còn hàng
   BOOKED: 1, // đã đặt
   SOLD: 2, // đã bán
   DELETED: 3 // đã xóa
}

export const KEY_DATE = {
   DATE_USE: 1, // ngày sử dụng voucher
   DATE_REGISTER: 2, // ngày đăng ký thông tin
   DATE_DELIVERY: 3 // ngày giao hàng
}

export const POSITION_INFO = {
   ALL: { id: '', name: 'ALL' },
   ADMIN: { id: 'admin', name: 'ADMIN' },
   ORDER_MODERATOR: { id: 'ORDER_MODERATOR', name: 'ORDER_MODERATOR' },
   ORDER_STAFF: { id: 'order', name: 'ORDER_STAFF' },
   SALES_MODERATOR: { id: 'SALES_MODERATOR', name: 'SALES_MODERATOR' },
   SALES_STAFF: { id: 'sale', name: 'SALES_STAFF' }
}
export const typeFund = {
   RECHARGE: 1, // nạp tiền
   WITHDRAW: 2, // rút tiền
   COLLECT: 3, // thu
   SPEND: 4, // chi
   REFUND: 5 //HOÀN TIỀN
}

export const statusFund = {
   NEW: 1,
   APPROVE: 2,
   REJECT: 3
}

export const statusFundTitle = [
   { id: 1, title: 'Mới' },
   { id: 2, title: 'Được chấp nhận' },
   { id: 3, title: 'Bị Từ chối' }
]
export const getStatusThuChi = (type: string | number) => {
   switch (type + '') {
      case '1':
         return 'Mới'
      case '2':
         return 'Được chấp nhận'
      // case '3':
      //    return "Từ chối"

      default:
         return 'Bị từ chối'
   }
}
export const TYPE_FUND = { THU: '0', CHI: '1', TAT_CA: '' } // 0 : thu ; 1 : chi
export const DEPOSIT_WITHDRAW_TYPE = [
   { id: 0, title: 'Tất cả' },
   { id: 3, title: 'Tiền thu' },
   { id: 4, title: 'Tiền chi' },
   { id: 2, title: 'Designer rút tiền' },
   { id: 1, title: 'Creator nộp tiền' },
   { id: 5, title: 'Hoàn tiền' }
] // 0 : thu ; 1 : chi

export const getTypeThuChi = (type: string | number) => {
   switch (type + '') {
      case '1':
         return 'Creator nộp tiền'
      case '2':
         return 'Designer rút tiền'
      case '3':
         return 'Thu tiền'
      case '4':
         return 'Chi tiền'
      default:
         return 'Hoàn tiền'
   }
}
export const DEPOSIT_WITHDRAW_TYPE_ID = { deposit: '0', withdraw: '1', TAT_CA: '' } // 0 : thu ; 1 : chi

export const MIN_DOB_FORMAT_CLIENT = '01/01/1910'
export const MAX_DOB_FORMAT_CLIENT = '31/12/2002'

export const MONTH_FORMAT_CLIENT = 'MM/YYYY'
export const NUMBER_FORMAT_CLIENT = '0,0'
export const NUMBER_FORMAT_SALARY_CLIENT = '0,0'
export const NUMBER_USD_FORMAT_SALARY_CLIENT = '0,0.00'
export const NUMBER_USD_FORMAT_SALARY_CLIENT_FULL = '0,0.000000000'

export const getPage = (offset: number, pageSize?: number) => {
   if (pageSize) {
      return offset ? offset * pageSize : 0
   } else {
      return offset ? offset * PAGE_SIZE : 0
   }
}

export const getBgColor = (type: string) => {
   switch (type) {
      case 'primary':
         return '#FFAD63'
      case 'secondary':
         return '#272944'
      case 'prioritize':
         return 'red'
      default:
         return '#0EC758'
   }
}

export const getNoti = (offset: number, notiSize?: number) => {
   if (notiSize) {
      return offset ? offset * notiSize : 0
   } else {
      return offset ? offset * NOTI_SIZE : 0
   }
}

export const getUniqueCode = () => {
   // const result = moment().format('YYMMDDHHmmss')
   const result = '#' + nanoid()
   // const result = txt.replace(/-/g, "")
   // console.log('getUniqueCodeTime', result)
   return result
}

export const getUniqueCodeBank = () => {
   // const result = moment().format('YYMMDDHHmmss')
   const result = nanoBank().toUpperCase()
   // const result = txt.replace(/-/g, "")
   // console.log('nanoBank', result)
   return result
}

export const bankAccount = {
   BANK_ID: 'TCB',
   ACCOUNT_NO: '330030',
   AMOUNT: '',
   DESCRIPTION: nanoBank().toUpperCase() || '', // Tối đa 50 kí tự
   ACCOUNT_NAME: 'PHAM QUANG VINH'
}

export const PhiSanMinMax = { min: 0, max: 100 }

export const ExchangeFeeMinMax = { min: 10000, max: 10000000 }

export const GiaMinMax = { min: 1000, max: 10000000, minNap: 10000, maxNap: 1000000000 }

export const MinMaxConfigDesigner = {
   timeJob: { min: 1, max: 100000 },
   amountHold: { min: 0, max: 100000000 },
   minWithdraw: { min: 10000, max: 100000000 },
   rangeDate: { min: 1, max: 360 },
   rangeDate0: { min: 0, max: 360 },
   maxReceive: { min: 1, max: 100 }
}
export const MinMaxRutTien = { min: 10000, max: 10000000 }
export const timeRangeFilterOptions = [
   {
      label: 'Thời gian cũ đến mới',
      value: 'createdAt,ASC'
   },
   {
      label: 'Thời gian mới đến cũ',
      value: 'createdAt,DESC'
   },
   {
      label: 'Giá từ thấp đến cao',
      value: 'totalPrice,ASC'
   },
   {
      label: 'Giá từ cao đến thấp',
      value: 'totalPrice,DESC'
   }
]

export const BANKS = [
   {
      id: 17,
      name: 'Ngân hàng TMCP Công thương Việt Nam',
      code: 'ICB',
      bin: '970415',
      shortName: 'VietinBank',
      logo: 'https://api.vietqr.io/img/ICB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'VietinBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'ICBVVNVX'
   },
   {
      id: 43,
      name: 'Ngân hàng TMCP Ngoại Thương Việt Nam',
      code: 'VCB',
      bin: '970436',
      shortName: 'Vietcombank',
      logo: 'https://api.vietqr.io/img/VCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Vietcombank',
      support: 3,
      isTransfer: 1,
      swift_code: 'BFTVVNVX'
   },
   {
      id: 4,
      name: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
      code: 'BIDV',
      bin: '970418',
      shortName: 'BIDV',
      logo: 'https://api.vietqr.io/img/BIDV.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'BIDV',
      support: 3,
      isTransfer: 1,
      swift_code: 'BIDVVNVX'
   },
   {
      id: 42,
      name: 'Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam',
      code: 'VBA',
      bin: '970405',
      shortName: 'Agribank',
      logo: 'https://api.vietqr.io/img/VBA.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Agribank',
      support: 3,
      isTransfer: 1,
      swift_code: 'VBAAVNVX'
   },
   {
      id: 26,
      name: 'Ngân hàng TMCP Phương Đông',
      code: 'OCB',
      bin: '970448',
      shortName: 'OCB',
      logo: 'https://api.vietqr.io/img/OCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'OCB',
      support: 3,
      isTransfer: 1,
      swift_code: 'ORCOVNVX'
   },
   {
      id: 21,
      name: 'Ngân hàng TMCP Quân đội',
      code: 'MB',
      bin: '970422',
      shortName: 'MBBank',
      logo: 'https://api.vietqr.io/img/MB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'MBBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'MSCBVNVX'
   },
   {
      id: 38,
      name: 'Ngân hàng TMCP Kỹ thương Việt Nam',
      code: 'TCB',
      bin: '970407',
      shortName: 'Techcombank',
      logo: 'https://api.vietqr.io/img/TCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Techcombank',
      support: 3,
      isTransfer: 1,
      swift_code: 'VTCBVNVX'
   },
   {
      id: 2,
      name: 'Ngân hàng TMCP Á Châu',
      code: 'ACB',
      bin: '970416',
      shortName: 'ACB',
      logo: 'https://api.vietqr.io/img/ACB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'ACB',
      support: 3,
      isTransfer: 1,
      swift_code: 'ASCBVNVX'
   },
   {
      id: 47,
      name: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
      code: 'VPB',
      bin: '970432',
      shortName: 'VPBank',
      logo: 'https://api.vietqr.io/img/VPB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'VPBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'VPBKVNVX'
   },
   {
      id: 39,
      name: 'Ngân hàng TMCP Tiên Phong',
      code: 'TPB',
      bin: '970423',
      shortName: 'TPBank',
      logo: 'https://api.vietqr.io/img/TPB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'TPBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'TPBVVNVX'
   },
   {
      id: 36,
      name: 'Ngân hàng TMCP Sài Gòn Thương Tín',
      code: 'STB',
      bin: '970403',
      shortName: 'Sacombank',
      logo: 'https://api.vietqr.io/img/STB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Sacombank',
      support: 3,
      isTransfer: 1,
      swift_code: 'SGTTVNVX'
   },
   {
      id: 12,
      name: 'Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
      code: 'HDB',
      bin: '970437',
      shortName: 'HDBank',
      logo: 'https://api.vietqr.io/img/HDB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'HDBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'HDBCVNVX'
   },
   {
      id: 44,
      name: 'Ngân hàng TMCP Bản Việt',
      code: 'VCCB',
      bin: '970454',
      shortName: 'VietCapitalBank',
      logo: 'https://api.vietqr.io/img/VCCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'VietCapitalBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'VCBCVNVX'
   },
   {
      id: 31,
      name: 'Ngân hàng TMCP Sài Gòn',
      code: 'SCB',
      bin: '970429',
      shortName: 'SCB',
      logo: 'https://api.vietqr.io/img/SCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'SCB',
      support: 3,
      isTransfer: 1,
      swift_code: 'SACLVNVX'
   },
   {
      id: 45,
      name: 'Ngân hàng TMCP Quốc tế Việt Nam',
      code: 'VIB',
      bin: '970441',
      shortName: 'VIB',
      logo: 'https://api.vietqr.io/img/VIB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'VIB',
      support: 3,
      isTransfer: 1,
      swift_code: 'VNIBVNVX'
   },
   {
      id: 35,
      name: 'Ngân hàng TMCP Sài Gòn - Hà Nội',
      code: 'SHB',
      bin: '970443',
      shortName: 'SHB',
      logo: 'https://api.vietqr.io/img/SHB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'SHB',
      support: 3,
      isTransfer: 1,
      swift_code: 'SHBAVNVX'
   },
   {
      id: 10,
      name: 'Ngân hàng TMCP Xuất Nhập khẩu Việt Nam',
      code: 'EIB',
      bin: '970431',
      shortName: 'Eximbank',
      logo: 'https://api.vietqr.io/img/EIB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Eximbank',
      support: 3,
      isTransfer: 1,
      swift_code: 'EBVIVNVX'
   },
   {
      id: 22,
      name: 'Ngân hàng TMCP Hàng Hải',
      code: 'MSB',
      bin: '970426',
      shortName: 'MSB',
      logo: 'https://api.vietqr.io/img/MSB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'MSB',
      support: 3,
      isTransfer: 1,
      swift_code: 'MCOBVNVX'
   },
   {
      id: 53,
      name: 'TMCP Việt Nam Thịnh Vượng - Ngân hàng số CAKE by VPBank',
      code: 'CAKE',
      bin: '546034',
      shortName: 'CAKE',
      logo: 'https://api.vietqr.io/img/CAKE.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'CAKE',
      support: 3,
      isTransfer: 1,
      swift_code: null
   },
   {
      id: 54,
      name: 'TMCP Việt Nam Thịnh Vượng - Ngân hàng số Ubank by VPBank',
      code: 'Ubank',
      bin: '546035',
      shortName: 'Ubank',
      logo: 'https://api.vietqr.io/img/UBANK.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Ubank',
      support: 3,
      isTransfer: 1,
      swift_code: null
   },
   {
      id: 58,
      name: 'Ngân hàng số Timo by Ban Viet Bank (Timo by Ban Viet Bank)',
      code: 'TIMO',
      bin: '963388',
      shortName: 'Timo',
      logo: 'https://vietqr.net/portal-service/resources/icons/TIMO.png',
      transferSupported: 1,
      lookupSupported: 0,
      short_name: 'Timo',
      support: 0,
      isTransfer: 1,
      swift_code: null
   },
   {
      id: 57,
      name: 'Viettel Money',
      code: 'VTLMONEY',
      bin: '971005',
      shortName: 'ViettelMoney',
      logo: 'https://api.vietqr.io/img/VIETTELMONEY.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'ViettelMoney',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 56,
      name: 'VNPT Money',
      code: 'VNPTMONEY',
      bin: '971011',
      shortName: 'VNPTMoney',
      logo: 'https://api.vietqr.io/img/VNPTMONEY.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'VNPTMoney',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 34,
      name: 'Ngân hàng TMCP Sài Gòn Công Thương',
      code: 'SGICB',
      bin: '970400',
      shortName: 'SaigonBank',
      logo: 'https://api.vietqr.io/img/SGICB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'SaigonBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'SBITVNVX'
   },
   {
      id: 3,
      name: 'Ngân hàng TMCP Bắc Á',
      code: 'BAB',
      bin: '970409',
      shortName: 'BacABank',
      logo: 'https://api.vietqr.io/img/BAB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'BacABank',
      support: 3,
      isTransfer: 1,
      swift_code: 'NASCVNVX'
   },
   {
      id: 30,
      name: 'Ngân hàng TMCP Đại Chúng Việt Nam',
      code: 'PVCB',
      bin: '970412',
      shortName: 'PVcomBank',
      logo: 'https://api.vietqr.io/img/PVCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'PVcomBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'WBVNVNVX'
   },
   {
      id: 27,
      name: 'Ngân hàng Thương mại TNHH MTV Đại Dương',
      code: 'Oceanbank',
      bin: '970414',
      shortName: 'Oceanbank',
      logo: 'https://api.vietqr.io/img/OCEANBANK.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Oceanbank',
      support: 3,
      isTransfer: 1,
      swift_code: 'OCBKUS3M'
   },
   {
      id: 24,
      name: 'Ngân hàng TMCP Quốc Dân',
      code: 'NCB',
      bin: '970419',
      shortName: 'NCB',
      logo: 'https://api.vietqr.io/img/NCB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'NCB',
      support: 3,
      isTransfer: 1,
      swift_code: 'NVBAVNVX'
   },
   {
      id: 37,
      name: 'Ngân hàng TNHH MTV Shinhan Việt Nam',
      code: 'SHBVN',
      bin: '970424',
      shortName: 'ShinhanBank',
      logo: 'https://api.vietqr.io/img/SHBVN.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'ShinhanBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'SHBKVNVX'
   },
   {
      id: 1,
      name: 'Ngân hàng TMCP An Bình',
      code: 'ABB',
      bin: '970425',
      shortName: 'ABBANK',
      logo: 'https://api.vietqr.io/img/ABB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'ABBANK',
      support: 3,
      isTransfer: 1,
      swift_code: 'ABBKVNVX'
   },
   {
      id: 41,
      name: 'Ngân hàng TMCP Việt Á',
      code: 'VAB',
      bin: '970427',
      shortName: 'VietABank',
      logo: 'https://api.vietqr.io/img/VAB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'VietABank',
      support: 3,
      isTransfer: 1,
      swift_code: 'VNACVNVX'
   },
   {
      id: 23,
      name: 'Ngân hàng TMCP Nam Á',
      code: 'NAB',
      bin: '970428',
      shortName: 'NamABank',
      logo: 'https://api.vietqr.io/img/NAB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'NamABank',
      support: 3,
      isTransfer: 1,
      swift_code: 'NAMAVNVX'
   },
   {
      id: 29,
      name: 'Ngân hàng TMCP Xăng dầu Petrolimex',
      code: 'PGB',
      bin: '970430',
      shortName: 'PGBank',
      logo: 'https://api.vietqr.io/img/PGB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'PGBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'PGBLVNVX'
   },
   {
      id: 46,
      name: 'Ngân hàng TMCP Việt Nam Thương Tín',
      code: 'VIETBANK',
      bin: '970433',
      shortName: 'VietBank',
      logo: 'https://api.vietqr.io/img/VIETBANK.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'VietBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'VNTTVNVX'
   },
   {
      id: 5,
      name: 'Ngân hàng TMCP Bảo Việt',
      code: 'BVB',
      bin: '970438',
      shortName: 'BaoVietBank',
      logo: 'https://api.vietqr.io/img/BVB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'BaoVietBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'BVBVVNVX'
   },
   {
      id: 33,
      name: 'Ngân hàng TMCP Đông Nam Á',
      code: 'SEAB',
      bin: '970440',
      shortName: 'SeABank',
      logo: 'https://api.vietqr.io/img/SEAB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'SeABank',
      support: 3,
      isTransfer: 1,
      swift_code: 'SEAVVNVX'
   },
   {
      id: 52,
      name: 'Ngân hàng Hợp tác xã Việt Nam',
      code: 'COOPBANK',
      bin: '970446',
      shortName: 'COOPBANK',
      logo: 'https://api.vietqr.io/img/COOPBANK.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'COOPBANK',
      support: 3,
      isTransfer: 1,
      swift_code: null
   },
   {
      id: 20,
      name: 'Ngân hàng TMCP Bưu Điện Liên Việt',
      code: 'LPB',
      bin: '970449',
      shortName: 'LienVietPostBank',
      logo: 'https://api.vietqr.io/img/LPB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'LienVietPostBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'LVBKVNVX'
   },
   {
      id: 19,
      name: 'Ngân hàng TMCP Kiên Long',
      code: 'KLB',
      bin: '970452',
      shortName: 'KienLongBank',
      logo: 'https://api.vietqr.io/img/KLB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'KienLongBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'KLBKVNVX'
   },
   {
      id: 55,
      name: 'Ngân hàng Đại chúng TNHH Kasikornbank',
      code: 'KBank',
      bin: '668888',
      shortName: 'KBank',
      logo: 'https://api.vietqr.io/img/KBANK.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'KBank',
      support: 3,
      isTransfer: 1,
      swift_code: 'KASIVNVX'
   },
   {
      id: 48,
      name: 'Ngân hàng Liên doanh Việt - Nga',
      code: 'VRB',
      bin: '970421',
      shortName: 'VRB',
      logo: 'https://api.vietqr.io/img/VRB.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'VRB',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 8,
      name: 'DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh',
      code: 'DBS',
      bin: '796500',
      shortName: 'DBSBank',
      logo: 'https://api.vietqr.io/img/DBS.png',
      transferSupported: 0,
      lookupSupported: 0,
      short_name: 'DBSBank',
      support: 0,
      isTransfer: 0,
      swift_code: 'DBSSVNVX'
   },
   {
      id: 49,
      name: 'Ngân hàng TNHH MTV Woori Việt Nam',
      code: 'WVN',
      bin: '970457',
      shortName: 'Woori',
      logo: 'https://api.vietqr.io/img/WVN.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'Woori',
      support: 0,
      isTransfer: 1,
      swift_code: null
   },
   {
      id: 50,
      name: 'Ngân hàng Kookmin - Chi nhánh Hà Nội',
      code: 'KBHN',
      bin: '970462',
      shortName: 'KookminHN',
      logo: 'https://api.vietqr.io/img/KBHN.png',
      transferSupported: 0,
      lookupSupported: 0,
      short_name: 'KookminHN',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 51,
      name: 'Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh',
      code: 'KBHCM',
      bin: '970463',
      shortName: 'KookminHCM',
      logo: 'https://api.vietqr.io/img/KBHCM.png',
      transferSupported: 0,
      lookupSupported: 0,
      short_name: 'KookminHCM',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 6,
      name: 'Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam',
      code: 'CBB',
      bin: '970444',
      shortName: 'CBBank',
      logo: 'https://api.vietqr.io/img/CBB.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'CBBank',
      support: 0,
      isTransfer: 0,
      swift_code: 'GTBAVNVX'
   },
   {
      id: 25,
      name: 'Ngân hàng Nonghyup - Chi nhánh Hà Nội',
      code: 'NHB HN',
      bin: '801011',
      shortName: 'Nonghyup',
      logo: 'https://api.vietqr.io/img/NHB.png',
      transferSupported: 0,
      lookupSupported: 0,
      short_name: 'Nonghyup',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 7,
      name: 'Ngân hàng TNHH MTV CIMB Việt Nam',
      code: 'CIMB',
      bin: '422589',
      shortName: 'CIMB',
      logo: 'https://api.vietqr.io/img/CIMB.png',
      transferSupported: 1,
      lookupSupported: 1,
      short_name: 'CIMB',
      support: 0,
      isTransfer: 1,
      swift_code: 'CIBBVNVN'
   },
   {
      id: 9,
      name: 'Ngân hàng TMCP Đông Á',
      code: 'DOB',
      bin: '970406',
      shortName: 'DongABank',
      logo: 'https://api.vietqr.io/img/DOB.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'DongABank',
      support: 0,
      isTransfer: 0,
      swift_code: 'EACBVNVX'
   },
   {
      id: 11,
      name: 'Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu',
      code: 'GPB',
      bin: '970408',
      shortName: 'GPBank',
      logo: 'https://api.vietqr.io/img/GPB.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'GPBank',
      support: 0,
      isTransfer: 0,
      swift_code: 'GBNKVNVX'
   },
   {
      id: 13,
      name: 'Ngân hàng TNHH MTV Hong Leong Việt Nam',
      code: 'HLBVN',
      bin: '970442',
      shortName: 'HongLeong',
      logo: 'https://api.vietqr.io/img/HLBVN.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'HongLeong',
      support: 0,
      isTransfer: 0,
      swift_code: 'HLBBVNVX'
   },
   {
      id: 40,
      name: 'Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh',
      code: 'UOB',
      bin: '970458',
      shortName: 'UnitedOverseas',
      logo: 'https://api.vietqr.io/img/UOB.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'UnitedOverseas',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 14,
      name: 'Ngân hàng TNHH MTV HSBC (Việt Nam)',
      code: 'HSBC',
      bin: '458761',
      shortName: 'HSBC',
      logo: 'https://api.vietqr.io/img/HSBC.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'HSBC',
      support: 0,
      isTransfer: 0,
      swift_code: 'HSBCVNVX'
   },
   {
      id: 15,
      name: 'Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội',
      code: 'IBK - HN',
      bin: '970455',
      shortName: 'IBKHN',
      logo: 'https://api.vietqr.io/img/IBK.png',
      transferSupported: 0,
      lookupSupported: 0,
      short_name: 'IBKHN',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 28,
      name: 'Ngân hàng TNHH MTV Public Việt Nam',
      code: 'PBVN',
      bin: '970439',
      shortName: 'PublicBank',
      logo: 'https://api.vietqr.io/img/PBVN.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'PublicBank',
      support: 0,
      isTransfer: 0,
      swift_code: 'VIDPVNVX'
   },
   {
      id: 16,
      name: 'Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh',
      code: 'IBK - HCM',
      bin: '970456',
      shortName: 'IBKHCM',
      logo: 'https://api.vietqr.io/img/IBK.png',
      transferSupported: 0,
      lookupSupported: 0,
      short_name: 'IBKHCM',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 18,
      name: 'Ngân hàng TNHH Indovina',
      code: 'IVB',
      bin: '970434',
      shortName: 'IndovinaBank',
      logo: 'https://api.vietqr.io/img/IVB.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'IndovinaBank',
      support: 0,
      isTransfer: 0,
      swift_code: null
   },
   {
      id: 32,
      name: 'Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam',
      code: 'SCVN',
      bin: '970410',
      shortName: 'StandardChartered',
      logo: 'https://api.vietqr.io/img/SCVN.png',
      transferSupported: 0,
      lookupSupported: 1,
      short_name: 'StandardChartered',
      support: 0,
      isTransfer: 0,
      swift_code: 'SCBLVNVX'
   }
]

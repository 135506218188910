import { isEmpty } from 'lodash'
import { observable, action } from 'mobx'
import moment from 'moment'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { ROLE } from 'src/helpers/constants'

export const jobDetailInit = {
   id: 0,
   name: '',
   count: 1,
   designTypeId: undefined,
   productTypeId: undefined,
   note: '',
   images: [],
   tag: '',
   avatar: '',
   status: 0,
   creatorId: 0,
   designerId: 0,
   price: 0,
   actuallyReceived: 0,
   totalActuallyReceived: 0,
   totalPrice: 0,
   url: '',
   countReject: 0,
   startedAt: '',
   expiresAt: '',
   deletedAt: '',
   createdAt: '',
   updatedAt: '',
   isPriority: '',
   productType: {
      id: null,
      name: null,
      countJob: null,
      deletedAt: null,
      createdAt: null,
      updatedAt: null
   },
   designType: {
      id: null,
      name: '',
      countJob: 0,
      deletedAt: null,
      createdAt: null,
      updatedAt: null
   },
   creator: {
      name: '',
      avatar: '',
      email: ''
   },
   designer: {
      name: '',
      avatar: '',
      email: ''
   }
}

export class JobDetailStore {
   @observable jobDetail = { ...jobDetailInit }
   @observable imageUrls: string[] = []
   @observable uploadUrls: string[] = []
   @observable comments: any[] = []

   @action setComments = (comments: any[]) => {
      this.comments = [...comments]
   }

   @action setNewComment = (newComment: any, role: string) => {
      let fromId
      if (role === ROLE.CREATOR) {
         fromId = this.jobDetail.creatorId
      } else if (role === ROLE.DESIGNER) {
         fromId = this.jobDetail.designerId
      }

      let isMyMessage = newComment?.from === fromId
      if (!isMyMessage) {
         console.log('newcomment')
         this.comments = [...this.comments, { ...newComment, createdAt: moment.now() }]
      }
   }

   @action setImageUrls = (imgs: string[]) => {
      this.imageUrls = [...imgs]
   }

   @action setUploadUrls = (imgs: string[]) => {
      this.uploadUrls = [...imgs]
   }

   @action setJobDetail = (jobDetail: any) => {
      this.jobDetail = { ...jobDetail }
   }

   @action setJobDetailName = (name: any) => {
      this.jobDetail.name = name
   }

   @action setJobDetailImages = (images: any) => {
      this.jobDetail.images = images
   }

   @action setJobDetailDescription = (description: any) => {
      this.jobDetail.note = description
   }

   @action setJobDetailProductTypeId = (productTypeId: any) => {
      this.jobDetail.productTypeId = productTypeId
   }

   @action setJobDetailDesignTypeId = (designTypeId: any) => {
      this.jobDetail.designTypeId = designTypeId
   }

   @action setJobDetailPrice = (price: any) => {
      this.jobDetail.price = price
   }

   @action setJobDetailTag = (tag: any) => {
      this.jobDetail.tag = tag ? tag?.join(', ') : ''
   }

   @action setJobDetailCount = (count: any) => {
      this.jobDetail.count = count
   }

   @action setJobDetailUrl = (url: any) => {
      this.jobDetail.url = url
   }

   @action setJobDetailStatus = (status: any) => {
      this.jobDetail.status = status
   }

   @action
   async getJobDetail(id: number) {
      const response = await Request2.getWithToken(api.job.getDetail + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response
      }
      return response
   }

   @action
   async getExchangeFee(params: any) {
      const response = await Request2.getWithTokenNoMessage(api.exchangeFee.getFeeInfo, params)
      if (!isEmpty(response)) {
         this.jobDetail = response
      }
   }

   @action
   async getLastJob() {
      const response = await Request2.getWithToken(api.job.lastJob)
      if (!isEmpty(response)) {
         this.jobDetail = response
      }
      return response
   }

   @action
   async postJob(data: any) {
      const response = await Request2.postWithToken(data, api.job.getList)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
      return response
   }

   @action
   async postJobReceive(id: number) {
      const response = await Request2.postWithToken({}, api.job.receive + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
   }

   @action
   async postJobReview(id: number) {
      const response = await Request2.postWithToken({}, api.job.review + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
   }

   @action
   async postJobReReceive(id: number) {
      const response = await Request2.postWithToken({}, api.job.reReceive + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
   }

   @action
   async postJobConfirm(id: number, status: boolean) {
      const response = await Request2.postWithToken({ reject: status }, api.job.confirm + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
   }

   @action
   async postDesignerUpdateURL(id: number, url: string) {
      const response = await Request2.postWithToken({ url: url }, api.job.designerUpdateUrl + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
   }

   @action
   async updateJob(id: number, data: any) {
      const response = await Request2.postWithToken(data, api.job.update + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = response.data
      }
      return response
   }

   @action
   async deleteJob(id: number) {
      const response = await Request2.postWithToken({}, api.job.delete + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = { ...jobDetailInit }
      }
   }

   @action
   async cancelJob(id: number) {
      const response = await Request2.postWithToken({}, api.job.cancel + `/${id}`)
      if (!isEmpty(response)) {
         this.jobDetail = { ...jobDetailInit }
      }
   }

   @action
   async rateJob(jobID: string | number, rate: string | number, note?: string) {
      const body = { rate, note: note || '' }
      const DOMAIN = `${api.job.rate}/` + jobID
      // console.log('\nDOMAIN; ', DOMAIN, 'body', body)
      const result = await Request2.putWithToken(body, DOMAIN)
      // console.log('result; rateJob', JSON.stringify(result), '\nDOMAIN; ', DOMAIN)
      return result?.data
   }

   @action
   async assignJobToDesigner(designerId: any, jobId: any) {
      const result = await Request2.postWithToken({ designerId }, api.job.assignee + `/${jobId}`)
      console.log('result', result);

      return result?.data
   }
   @action
   async setJobPriority(jobId: any, isPriority: boolean) {
      const result = await Request2.postWithToken({ isPriority }, api.job.priority + `/${jobId}`)
      console.log('result', result);

      return result?.data
   }
   @action
   async countCancel() {
      const result = await Request2.getWithToken(api.job.countCancel)
      console.log('result', result);

      return result
   }
   @action
   async resetJobDetail() {
      this.jobDetail = { ...jobDetailInit }
   }
}

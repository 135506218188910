import React from 'react'
import { Row, Col, Avatar } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useStore } from 'src/hooks'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { CONFIG_URL, DATE_TIME_FORMAT_CLIENT_NEW, ROLE } from 'src/helpers/constants'
import Images from 'src/common/Images'
import { isEmpty } from 'lodash'
import { formatCurrency } from 'src/helpers/functions'

const JobDetailOrderInfo = () => {
   const { t } = useTranslation()
   const CommonConfigStore = useStore('CommonConfigStore')
   const { designTypesList, productTypesList } = CommonConfigStore
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role

   const JobDetailOrderInfoItem = ({ title, avatar, value, isShowAvatar = false, align = 'center' }: any) => (
      <Col className="gx-px-1 gx-flex-column gx-my-1">
         <Row
            gutter={[0, 8]}
            className={`gx-flex-row ${align === 'center' && 'gx-align-items-center'} gx-justify-content-start`}
         >
            <Col
               xs={24}
               sm={8}
               md={6}
               style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}
               flex="150px"
            >
               {title}
            </Col>
            <Col
               style={{
                  maxWidth: 'calc(100% - 150px)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical'
               }}
               flex="1"
            >
               <Row
                  gutter={[8, 0]}
                  className={`gx-flex-row ${align === 'center' && 'gx-align-items-center'} gx-justify-content-start`}
               >
                  {isShowAvatar && (
                     <Col>
                        <Avatar
                           size={28}
                           src={isEmpty(avatar) ? Images.icon_avatar : CONFIG_URL.IMAGE_URL + '/' + avatar}
                        />
                     </Col>
                  )}
                  <Col
                     style={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '22px',
                        color: '#5C5C5C',
                        maxWidth: 'calc(100% - 16px)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 10,
                        WebkitBoxOrient: 'vertical'
                     }}
                  >
                     {value}
                  </Col>
               </Row>
            </Col>
         </Row>
      </Col>
   )

   return (
      <>
         <JobDetailOrderInfoItem
            title={t(GLOBAL_CLIENT.creator)}
            avatar={jobDetail?.creator?.avatar}
            value={jobDetail?.creator?.name ?? jobDetail?.creator?.email}
            isShowAvatar={true}
         />
         {jobDetail?.designer && (
            <JobDetailOrderInfoItem
               title={t(GLOBAL_CLIENT.designer)}
               avatar={jobDetail?.designer?.avatar}
               value={jobDetail?.designer?.name}
               isShowAvatar={true}
            />
         )}
         <JobDetailOrderInfoItem
            title={t(GLOBAL_CLIENT.createAt)}
            value={moment(jobDetail?.createdAt).format(DATE_TIME_FORMAT_CLIENT_NEW)}
         />
         <JobDetailOrderInfoItem
            title={t(GLOBAL_CLIENT.request)}
            align="start"
            value={
               <div>
                  <span>
                     {jobDetail?.designType?.name ??
                        designTypesList.find((option: any) => option?.id === jobDetail?.designTypeId)?.name}{' '}
                     {' - '}
                  </span>

                  <span>
                     {jobDetail?.productType?.name ??
                        productTypesList.find((option: any) => option?.id === jobDetail?.productTypeId)?.name}{' '}
                     {' - '}
                  </span>
                  {/* {productTypesList.find((option: any) => option?.id === jobDetail?.productTypeId)?.name} {' - '}
                  {designTypesList.find((option: any) => option?.id === jobDetail?.designTypeId)?.name} {' - '} */}
                  <span>
                     {jobDetail?.count} {t(GLOBAL_CLIENT.model)}
                  </span>
               </div>
            }
         />
         <JobDetailOrderInfoItem
            title={t(GLOBAL_CLIENT.tongtien)}
            value={
               <span style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}>
                  {role === ROLE.CREATOR
                     ? formatCurrency(jobDetail?.totalPrice, true)
                     : formatCurrency(jobDetail?.totalActuallyReceived, true)}
                  {' ' + t(GLOBAL_CLIENT.vnd)}
               </span>
            }
         />
      </>
   )
}

export default JobDetailOrderInfo

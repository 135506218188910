import { Divider } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatCurrency } from 'src/helpers/functions'
import CardItemButton from './CardItemButton'
import { isEmpty } from 'lodash'
import Images from 'src/common/Images'
import { CONFIG_URL, DATE_TIME_FORMAT_CLIENT, ROLE } from 'src/helpers/constants'
import { ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useStore } from 'src/hooks'

type JobCardItemProps = {
   data: any
   grid: boolean
   jobsStatus?: number
   handleRefreshJobs: () => void
   handleShowDetail: () => void
}

const JobCardContentWrapper = styled.div`
   margin-top: 18px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   .header-body__wrapper {
      padding: 0 16px;
      padding-top: unset;
      display: flex;
      flex-direction: column;
      flex: 1;
      color: #000;
   }
`

const JobCardImageWrapper = styled.div`
   padding: 16px;
   padding-bottom: unset;
   img {
      object-fit: cover;
      border-radius: 8px;
      width: 100%;
      height: 300px;
      &.default-img {
         object-fit: cover;
      }
      @media (max-width: 1600px) {
         height: 210px;
      }
      @media (max-width: 575px) {
         height: 350px;
      }
   }
`

const ContentHeaderWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   p {
      margin: unset;
   }
   .content-design__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: ${(props) => props.theme.color.grey_1};
   }
   .customer__avatar {
      border-radius: 50%;
      width: 28px;
      min-width: 28px;
      height: 28px;
      margin-left: 4px;
      img {
         border-radius: 50%;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   // .content-header__customer-name {
   //    font-weight: bold;
   //    text-align: center;
   //    margin-top: auto;
   //    margin-bottom: auto;
   //    padding-left: 4px;
   //    display: -webkit-box;
   //    -webkit-line-clamp: 1;
   //    -webkit-box-orient: vertical;
   //    overflow: hidden;
   // }
`

const ContentBodyWrapper = styled.div`
   p {
      margin: unset;
   }
   margin-top: 10px;
   display: flex;
   flex-direction: column;
   gap: 4px;
   .content-body__item {
      display: flex;
      gap: 6px;
      & > span {
         margin-top: auto;
         margin-bottom: auto;
      }
      p {
         margin: unset;
         font-weight: 400;
         font-size: 14px;
         line-height: 22px;

         color: ${(props) => props.theme.color.grey_1};
      }
   }
`

const ContentFooterWrapper = styled.div`
   padding: 0 16px 16px;
   display: flex;
   justify-content: space-between;
   .row {
      padding-right: 10px;
   }
   .content-footer__price2 {
      margin-top: auto;
      color: #404145;
      margin-bottom: auto;
      font-weight: small;
      font-size: 9px;
   }
   .content-footer__price {
      margin-top: auto;
      margin-bottom: auto;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: ${(props) => props.theme.color.dark_2};
   }
   .content-footer__btn {
      margin-top: auto;
      margin-bottom: auto;
   }
`

const JobCardWrapper = styled.div`
   background-color: ${(props) => props.theme.color.white};
`
const JobCardItem: React.FC<JobCardItemProps> = ({ data, grid, jobsStatus, handleRefreshJobs, handleShowDetail }) => {
   const {
      creator,
      createdAt,
      price,
      designType,
      productType,
      name,
      count,
      id,
      totalPrice,
      totalActuallyReceived,
      isPriority
   } = data
   // console.log('dataaa---', data)

   const [images, setImages] = useState<any>(null)
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   useEffect(() => {
      if (isEmpty(data?.images)) return
      setImages(data?.images[0])
   }, [data])

   const handleClickCardButton = () => {
      handleRefreshJobs()
      handleShowDetail()
   }

   const setDefaultImage = (e: any) => {
      e.target.src = Images.image_not_found
      e.target.className = 'default-img'
   }
   return (
      <JobCardWrapper
         onClick={handleShowDetail}
         className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}
      >
         <JobCardImageWrapper className="gx-product-image">
            <img
               alt={`${images?.description}`}
               src={`${CONFIG_URL.IMAGE_URL + '/' + images?.path}`}
               onError={setDefaultImage}
            />
         </JobCardImageWrapper>

         <JobCardContentWrapper>
            <div className="header-body__wrapper">
               <ContentHeaderWrapper className="product-body__header">
                  <p className="content-design__title text-oneline">{name}</p>
                  <div className="customer__avatar">
                     <img
                        src={creator?.avatar ? CONFIG_URL.IMAGE_URL + '/' + creator?.avatar : Images.icon_avatar}
                        alt="avatar"
                     />
                  </div>
                  {/* <div className="content-header__customer-wrapper">
                     <div className="customer__avatar">
                        <img
                           src={creator?.avatar ? CONFIG_URL.IMAGE_URL + '/' + creator?.avatar : Images.icon_avatar}
                           alt="avatar"
                        />
                     </div>
                     <p className="content-header__customer-name text-oneline">{creator?.name || creator?.email}</p>
                  </div>
                  <p className="content-header__created_at">{moment(createdAt).format(DATE_TIME_FORMAT_CLIENT)}</p> */}
               </ContentHeaderWrapper>
               <ContentBodyWrapper>
                  <div className="content-body__item">
                     <ClockCircleOutlined size={15} />
                     <p className="content-body__created_at text-oneline">
                        {moment(createdAt).format(DATE_TIME_FORMAT_CLIENT)}
                     </p>
                  </div>
                  <div
                     className="content-body__item"
                     title={designType?.name + ' - ' + productType?.name + ' - ' + count + ' mẫu'}
                  >
                     <InfoCircleOutlined size={15} />
                     <p style={{ maxWidth: '100%' }} className="text-oneline">
                        {designType?.name + ' - ' + productType?.name + ' - ' + count + ' mẫu'}
                     </p>
                  </div>
               </ContentBodyWrapper>
            </div>
            <Divider />
            <ContentFooterWrapper>
               <div className="row">
                  {/* <p className="content-footer__price2">Giá 1 mẫu</p> */}
                  <p className="content-footer__price text-oneline">
                     {role === ROLE.CREATOR
                        ? formatCurrency(totalPrice, true)
                        : formatCurrency(totalActuallyReceived, true)}{' '}
                     VNĐ
                  </p>
               </div>
               <CardItemButton
                  status={jobsStatus}
                  // isPriority={isPriority}
                  isPriority={role === ROLE.DESIGNER ? isPriority : false}
                  id={id}
                  count={count}
                  handleRefreshJobs={handleClickCardButton}
               />
            </ContentFooterWrapper>
         </JobCardContentWrapper>
      </JobCardWrapper>
   )
}

export default JobCardItem

import React from 'react'
import styled from 'styled-components'
const Wrapper: any = styled.button<any>`
   color: white;
   border: none;
   border-radius: 4px;
   align-items: center;
   cursor: pointer;
   font-weight: 400;
   white-space: nowrap;
   &:hover {
      opacity: 0.9;
   }
   background-color: ${(props) => {
      switch (props?.type) {
         case 'primary': {
            return props.theme.color.primary
         }
         case 'prioritize':
            return 'red'
         case 'secondary': {
            return props.theme.color.dark_2
         }
         case 'info': {
            return props.theme.color.info
         }
         case 'success': {
            return props.theme.color.success
         }
      }
   }};
`
type ButtonProps = {
   type?: string
   size?: string
   padding?: string
   width?: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const BaseButton: React.FC<ButtonProps> = ({ size = 'md', type = 'primary', width, children, padding, ...rest }) => {
   const getstyle = () => {
      switch (size) {
         case 'sm': {
            return {
               fontSize: '12px',
               lineHeight: '22px',
               padding: padding ? padding : '2px 8px'
            }
         }
         case 'md': {
            return {
               fontSize: '14px',
               lineHeight: '22px',
               padding: padding ? padding : '8px 16px',
               borderRadius: '8px'
            }
         }
         case 'lg': {
            return {
               fontSize: '14px',
               lineHeight: '22px',
               padding: padding ? padding : '8px 24px',
               borderRadius: '8px'
            }
         }
      }
   }

   return (
      <Wrapper
         type={type}
         {...rest}
         style={{
            width: width ? width : '',
            ...getstyle()
         }}
      >
         {children}
      </Wrapper>
   )
}

export default BaseButton
